<template>
  <div
    :class="{ 'low-opacity' : lowOpacity }"
    class="mm-product-characteristics"
  >
    <div v-if="columnView">
      <ProductMainCharacteristicItem
        v-for="(char, key) in shownCharacteristics"
        :key="`${updateVersion}_${key}`"
        :label="char.label"
        :value="char.value"
        :pre-label="char.preLabel"
        :big-margin-bottom="bigMarginBottom"
        :row-characteristic-id="char.rowCharacteristicId"
        :item-characteristic-value="char.itemCharacteristicIdValue"
      />
    </div>
    <div v-else>
      <ProductCharacteristicItem
        v-for="(char, key) in shownCharacteristics"
        :key="`${updateVersion}_${key}`"
        :label="char.label"
        :value="char.value"
        :size="size"
        :full-view="char.fullView"
        :high-value="highValue"
        :has-handler="char.hasHandler"
        :big-value-font="bigValueFont"
        :big-margin-bottom="bigMarginBottom"
        :wrap-value="wrapValue"
        :half-width-mobile="halfWidthMobile"
        :is-has-link="char.isHasLink"
        :with-secondary-text="char.withSecondaryText"
        :pre-label="char.preLabel"
        :class="{ 'with-handler': char.hasHandler }"
        :row-characteristic-id="char.rowCharacteristicId"
        :item-characteristic-value="char.itemCharacteristicIdValue"
        :value-warn="char.valueWarn"
        :bold-value="char.boldValue"
        :bold-label="char.boldLabel"
      >
        <template
          v-if="char.hasHandler"
          #rowHandler
        >
          <button
            class="mm-product-characteristics__button"
            :class="char.additionalHandlerClass"
            @click="char.handler()"
          >
            {{ char.buttonName }}
          </button>
        </template>
      </ProductCharacteristicItem>
    </div>
    <div
      v-if="isShowButton && !noButton"
      class="btn-secondary"
      @click="onShowCharacteristics"
    >
      {{ buttonLabel ?? 'Показать все характеристики' }}
    </div>
    <div
      v-if="isHideButton && !isShowButton && !noButton"
      class="btn-secondary"
      @click="onHideCharacteristics"
    >
      Свернуть все характеристики
    </div>
  </div>
</template>

<script lang="ts" setup>
import IProductCharacteristic from '../models/productCharacteristic.model';
import ProductCharacteristicItem from './ProductCharacteristicItem.vue';
import ProductMainCharacteristicItem from './ProductMainCharacteristicItem.vue';
import { useUpdate } from '../composables/useUpdate';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  // characteristics - Отображаемые элементы, массив (минимальное для отображения: { label, value }), если не передать - не отобразятся характеристики
  characteristics: Array<IProductCharacteristic>;
  // maxElements - максимальное количество элементов в колонке, если не передать - покажет все, если передать - добавится кнопка показать еще
  maxElements?: number;
  // size - 12px отступ у каждой строки, если не передать - будет 4
  size?: string;
  // buttonLabel - кастомный текст вместо 'Показать все характеристики' на случай, если передан проп maxElements, если не передать - будет дефолтный
  buttonLabel?: string;
  // highValue - перенос нижнего подчеркивания на 1 строку, если строка перенеслась на несколько строк, если не передать - будет внизу
  highValue?: boolean;
  // narrowColumnWidth - включение режима нескольких колонок, если не передать - будет 1 колонка
  narrowColumnWidth?: boolean;
  // isHideButton - отключение кнопки, если передан проп maxElements, если не передать - будет отображаться
  isHideButton?: boolean;
  // bigValueFont - 14 шрифт, если не передать - будет 12
  bigValueFont?: boolean;
  // noButton - отключение кнопки свернуть все характеристики и показать все характеристики, если не передать и будет указан maxElements - то покажется
  noButton?: boolean;
  // wrapValue - автоматический перенос строк, если не передать - не будет переноса
  wrapValue?: boolean;
  // bigMarginBottom - больше отступ на каждой строке, если не передать - будет стандартный
  bigMarginBottom?: boolean;
  // halfWidthMobile - фиксированная ширина 50% у названий и значений на мобильных устройствах, если не передать - будет auto
  halfWidthMobile?: boolean;
  // fullView - 100% ширина у всего компонента, если не передать - будет auto
  fullView?: boolean;
  // lowOpacity - непрозрачность 0.2 у подчеркиваний строки, если не передать - будет 1
  lowOpacity?: boolean;
}>();

const watchSubscription = new WatchSubscription();
const { updateVersion } = useUpdate();
const maxCharacteristics = computed(() => props.maxElements ?? 0);
const isShowButton = ref<boolean>(maxCharacteristics.value > 0 && props.characteristics?.length > maxCharacteristics.value);
const columnView = ref<boolean>(props.narrowColumnWidth);
const shownCharacteristics = computed<Array<IProductCharacteristic>>(() => isShowButton.value ?
  props.characteristics?.slice(0, maxCharacteristics.value > 0 ? maxCharacteristics.value : props.characteristics?.length) : props.characteristics,
);

watchSubscription.add(
  watch(() => props.narrowColumnWidth, onUpdateColumnView),
);

useSSRUnsubscribeWatch(watchSubscription);

function onUpdateColumnView(): void {
  columnView.value = props.narrowColumnWidth;
}

function onShowCharacteristics(): void {
  isShowButton.value = false;
}

function onHideCharacteristics(): void {
  isShowButton.value = true;
}
</script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

.mm-product-characteristics {
  .btn-secondary {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 27px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &.low-opacity {
    .mm-product-characteristic-wrapper.mm-product-characteristic-item {
      &:before {
        opacity: 0.2 !important;
      }
    }
  }

  &__button {
    color: $link;
    font-weight: 500;
    text-decoration: none;
    margin-left: 12px;
    cursor: pointer;
    background: none;
    border: none;
  }
}
</style>
